import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useGetCookieValue } from '../utilities/cookie';
import RegisterForm from '../components/RegisterForm';

export default function ResetPassword(prop) {
  const navigate = useNavigate();
  const location = useLocation();
  const queryToken = location.search.substring(1);
  const cookieToken = useGetCookieValue("token");
  
  const [token] = useState(queryToken ? queryToken : cookieToken);
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [error, setError] = useState('');
  const [passwordError, setPasswordError] = useState('');
  const [success, setSuccess] = useState(false);
  const [backButtonText] = useState(queryToken ? 'Home': 'Back');

  useEffect(() => {
    if(!token){
      navigate("/");
    }
  },[token, navigate])

  const onResetButtonClick = () => {
    setError('');
    setPasswordError('');
  
    if ('' === password) {
      setPasswordError('Please enter a password');
      return;
    }
  
    if (password.length < 7) {
      setPasswordError('The password must be 8 characters or longer');
      return;
    }

    if (password !== confirmPassword){
      setPasswordError('Confirmed password is not matched');
    }

    const reset_password_url = process.env.REACT_APP_SERVER + "/reset_password";
    const reset_password_token = process.env.REACT_APP_LOGIN_TOKEN;
    const reset_password_form = new FormData();
    reset_password_form.append("password", password);
    reset_password_form.append("token", token);
    fetch(reset_password_url, {
      method: "POST",
      headers: {
        "x-authorization-token" :reset_password_token
      },
      body: reset_password_form
    })
    .then(
      async (response) => {
        const data = await response.json();

        if (response.ok){
          setSuccess(true);
        } else {
          const error = (data && data.message) || response.statusText;
          return Promise.reject(error);
        }
    })
    .catch(error => setError(error));
  }

  const onBackButtonClick = () => {
    if(queryToken){
      navigate('/');
    } else {
      navigate(-1);
    }
  }

  if(success){
    return(
      <RegisterForm title={"Reset Password"}>
        <div className={'row'}>
          <p>Congratulations!</p>
          <p>Your password was reset</p>
        </div>
        <input
          type="button"
          onClick={onBackButtonClick}
          value={backButtonText}
        />
      </RegisterForm>
    )
  }
  else{
    return (
      <RegisterForm title={"Reset Password"}>
        <div className={'row'}>
          <label className="error">{error}</label>
        </div>
        <div className={'row'}>
          <label>New Password</label>
          <input
            value={password}
            placeholder="Enter your password here"
            onChange={(ev) => setPassword(ev.target.value)}
            type="password"
            autoComplete='off'
          />
          <label className="error">{passwordError}</label>
        </div>
        <div className={'row'}>
          <label>Confirm New Password</label>
          <input
            value={confirmPassword}
            placeholder="Enter your confirm password here"
            onChange={(ev) => setConfirmPassword(ev.target.value)}
            type="password"
            autoComplete='off'
          />
        </div>
        <input type="button" onClick={onResetButtonClick} value={'Reset'} />
      </RegisterForm>
    )
  }
}