import { useEffect, useState } from 'react';
import { NavLink } from 'react-router-dom';
import LoginMenu from './LoginMenu';
import { useGetCookieValue } from '../utilities/cookie'
import './Menu.css';

export default function Menu(props) {
  const {defaultMenuData, loggedInMenuData} = props
  const [navs, setNavs] = useState(defaultMenuData);
  var token = useGetCookieValue("token")

  useEffect(() => {
    if(token){
      setNavs(loggedInMenuData) 
    } else {
      setNavs(defaultMenuData)
    }
  }, [token, defaultMenuData, loggedInMenuData])

  return (
    <div className='menu'>
      <div className='menuLogo'>
        <img src={'img/logo.png'} alt={'O2DES logo'} width={'100%'} height={'100%'}  />
      </div>
      <div className='menuLink'>
        <ul className='menu'>
        {navs.map((nav, index) => (
          <li key={index}>
            <NavLink to={nav.link}>
              {nav.title}
            </NavLink>
          </li>
        ))}
        </ul>
      </div>
      <LoginMenu />
    </div>
  );
}