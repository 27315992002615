import React, { useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useGetCookieValue } from '../utilities/cookie';
import './design.css'

export default function Design(prop) {
  const [blobUrl, setBlobUrl] = useState('about:blank');
  const navigate = useNavigate();
  const token = useGetCookieValue("token");
  const src = process.env.REACT_APP_SERVER +"/design";
  const headers = useMemo(() => ({ "x-authorization-token" : token }), [token]);
  
  useEffect(() => {
    if(token && token !== "undenfined"){
      const options = { 
        method: "GET",
        headers: headers, 
        mode: "cors",
      };
      
      fetch(src, options)
      .then(async (response) => { 
        if(response.ok){
          var blob = await response.blob();
          var url = URL.createObjectURL(blob);
          setBlobUrl(url);
        } else {
          var data = await response.json();
          const error = (data && data.message) || response.statusText;
          if (response.status === 401) {
            navigate("/logout");
          }
          return Promise.reject(error);
        }
      })
      .catch(error => {
        console.error("Error", error) ;
        navigate("/login");
      })
    } else {
      navigate("/login");
    }
  },[token, headers, src, navigate])

  return (
    <div className='mainContainer designBackground'>
      <div className='design' >
        <iframe src={blobUrl} title="designPage" />
      </div>
    </div>
  )
}