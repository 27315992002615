import React, { useState } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import RegisterForm from '../components/RegisterForm';

export default function Register(prop) {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [error, setError] = useState('');
  const [nameError, setNameError] = useState('');
  const [emailError, setEmailError] = useState('');
  const [passwordError, setPasswordError] = useState('');
  const [success, setSuccess] = useState(false);
  
  const navigate = useNavigate();

  const onRegisterButtonClick = () => {
    setError('');
    setNameError('');
    setEmailError('');
    setPasswordError('');

    // Check if the user has entered both fields correctly
    if ('' === name.trim()) {
      setNameError('Please enter your name');
      return;
    }
  
    // Check if the user has entered both fields correctly
    if ('' === email.trim()) {
      setEmailError('Please enter your email');
      return;
    }
  
    if (!/^[\w-.]+@([\w-]+.)+[\w-]{2,4}$/.test(email)) {
      setEmailError('Please enter a valid email');
      return;
    }
  
    if ('' === password) {
      setPasswordError('Please enter a password');
      return;
    }
  
    if (password.length < 7) {
      setPasswordError('The password must be 8 characters or longer');
      return;
    }

    if (password !== confirmPassword){
      setPasswordError('Confirmed password is not matched');
    }

    const register_url = process.env.REACT_APP_SERVER + "/register"
    const register_token = process.env.REACT_APP_LOGIN_TOKEN
    const register_form = new FormData()
    register_form.append("name", name)
    register_form.append("email", email)
    register_form.append("password", password)
    fetch(register_url, {
      method: "POST",
      headers: {
        "x-authorization-token" :register_token
      },
      body: register_form
    })
    .then(
      async (response) => {
        const data = await response.json();

        if (response.ok){
          setSuccess(true);
        } else {
          const error = (data && data.message) || response.statusText;
          return Promise.reject(error);
        }
    })
    .catch(error => setError(error))
  }

  const onLoginButtonClick = () => {
    navigate('/login');
  }

  if(success)
  {
    return(
      <RegisterForm title={"Sign Up"}>
        <div className={'row'}>
          <p>Congratulations!</p>
          <p>The user registration was successful</p>
          <p>Please login to use O2DES Web</p>
        </div>
        <input
          type="button"
          onClick={onLoginButtonClick}
          value={'Login'}
        />
      </RegisterForm>
    )
  }
  else
  {
    return (
      <RegisterForm title={"Sign Up"}>
        <div className={'row'}>
          <label className="error">{error}</label>
        </div>
        <div className={'row'}>
          <label>Name</label>
          <input
            value={name}
            placeholder="Enter your name here"
            onChange={(ev) => setName(ev.target.value)}
            autoComplete='name'
          />
          <label className="error">{nameError}</label>
        </div>
        <div className={'row'}>
          <label>Email</label>
          <input
            value={email}
            placeholder="Enter your email here"
            onChange={(ev) => setEmail(ev.target.value)}
            autoComplete='email'
          />
          <label className="error">{emailError}</label>
        </div>
        <div className={'row'}>
          <label>Password</label>
          <input
            value={password}
            placeholder="Enter your password here"
            onChange={(ev) => setPassword(ev.target.value)}
            type="password"
            autoComplete='off'
          />
          <label className="error">{passwordError}</label>
        </div>
        <div className={'row'}>
          <label>Confirm Password</label>
          <input
            value={confirmPassword}
            placeholder="Enter your confirm password here"
            onChange={(ev) => setConfirmPassword(ev.target.value)}
            type="password"
            autoComplete='off'
          />
        </div>
        <input type="button" onClick={onRegisterButtonClick} value={'Sign Up'} />
        <p>Already have a account, <Link to={'/login'}>Login</Link></p>
      </RegisterForm>
    )
  }
}