import React  from 'react';
import FormBase from '../components/FormBase';
import ReactPlayer from 'react-player'

export default function Tutorial(prop) {

  return (
    <FormBase title="Tutorial">
      <ReactPlayer url="media/tutorial.mp4" width="100%" height="99%" controls={true} />
    </FormBase>
  )
}
