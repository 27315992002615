import { useState } from 'react';
import { useNavigate, NavLink } from 'react-router-dom';
import { useGetCookieValue } from '../utilities/cookie';
import './LoginMenu.css';

export default function LoginMenu(props){
  const navigate = useNavigate();
  const token = useGetCookieValue("token");
  const name = useGetCookieValue("name");
  const [userClick, setUserClick] = useState(false);

  const onLoginButtonClick = () => {
    navigate('/login');
  }

  const onRegisterButtonClick = () => {
    navigate('/register');
  }

  const onUserButtonClick = () => {
    setUserClick(!userClick);
  }

  if(token){
    return(
      <div className='loginMenu' >
        <div className='user'>
          <input
            className='menuButton'
            type="button"
            onClick={onUserButtonClick}
            value={name}
          />
          <div className="userMenu" style={{display : userClick?"flex":"none"}} onClick={onUserButtonClick}>
            <NavLink to={'/profile'}>Profile</NavLink>
            <NavLink to={'/reset_password'}>Reset Password</NavLink>
            <NavLink to={'/logout'}>Logout</NavLink>
          </div>
        </div>
      </div>
    );
  } else {
    return(
      <div className='loginMenu' >
        <div>
          <input
            className='registerButton'
            type="button"
            onClick={onRegisterButtonClick}
            value={'Sign up'}
          />
        </div>
        <div>
          <input
            className='loginButton'
            type="button"
            onClick={onLoginButtonClick}
            value={'Login'}
          />
        </div>
      </div>
    );
  }
}

