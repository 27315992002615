import React, { useState, useEffect } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { useGetCookieValue, useSetCookie } from '../utilities/cookie';
import LoginForm from '../components/LoginForm';
import './login.css';

export default function Login(prop) {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [remember, setRemember] = useState(true);
  const [error, setError] = useState('');
  const [emailError, setEmailError] = useState('');
  const [passwordError, setPasswordError] = useState('');

  const navigate = useNavigate();
  const setCookie = useSetCookie(); 
  const token = useGetCookieValue("token");

  useEffect(() => {
    if(token){
      navigate('/design');
    }
  }, [token, navigate])


  const onButtonClick = () => {
    setError('');
    setEmailError('');
    setPasswordError('');
  
    // Check if the user has entered both fields correctly
    if ('' === email) {
      setEmailError('Please enter your email');
      return;
    }
  
    if (!/^[\w-.]+@([\w-]+.)+[\w-]{2,4}$/.test(email)) {
      setEmailError('Please enter a valid email');
      return;
    }
  
    if ('' === password) {
      setPasswordError('Please enter a password');
      return;
    }
  
    if (password.length < 7) {
      setPasswordError('The password must be 8 characters or longer');
      return;
    }

    const login_url = process.env.REACT_APP_SERVER + "/login";
    const login_token = process.env.REACT_APP_LOGIN_TOKEN;
    const login_form = new FormData();
    login_form.append("login", email);
    login_form.append("password", password);
    login_form.append("remember", remember?"true":"false");
    fetch(login_url, {
      method: "POST",
      headers: {
        "x-authorization-token" :login_token
      },
      body: login_form
    })
    .then(
      async (response) => {
        const data = await response.json();

        if (response.ok){
          const expiry = new Date(data['expiry']);
          const options = {expires : expiry};
          setCookie(data, options);
          navigate('/design');
        } else {
          const error = (data && data.message) || response.statusText
          return Promise.reject(error);
        }
    })
    .catch(error => setError(error))
  }

  return (
    <LoginForm title={"User Login"}>
      <div className={'row'}>
        <label className="error">{error}</label>
      </div>
      <div className={'row'}>
        <label>Email</label>
        <input
          value={email}
          placeholder="Enter your email here"
          onChange={(ev) => setEmail(ev.target.value)}
          autoComplete='email'
        />
        <label className="error">{emailError}</label>
      </div>
      <div className={'row'}>
        <label>Password</label>
        <input
          value={password}
          placeholder="Enter your password here"
          onChange={(ev) => setPassword(ev.target.value)}
          type="password"
          autoComplete='password'
        />
        <label className="error">{passwordError}</label>
      </div>
      <div className={'loginContentControl'}>
        <div className={'checkbox rememberMe'}>
          <label>Remember me</label>
          <input type="checkbox" id="remember_me_check" checked={remember} onChange={() => setRemember(!remember)}/>
        </div>
        <div className={'forgetPassword'}>
          <Link to={"/forget_password"}>forget password</Link>
        </div>
      </div>         
      <input type="button" onClick={onButtonClick} value={'Log in'} />
      <p>Need an account, <Link to={'/register'}>SIGN UP</Link></p>
    </LoginForm>
  )
}