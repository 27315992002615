import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useGetCookieValue } from '../utilities/cookie';
import './home.css';

export default function Home(prop) {
  const navigate = useNavigate();

  const onButtonClick = () => {
    navigate('/login');
  }

  var loginText = 'Start Now'
  var token = useGetCookieValue("token");
  if(token){
    loginText = "Start Now";
  }

  return (
    <div className={"mainContainer homeBackground"}>
      <div className={"home"}>
        <div className={"logo"}>
          <div className={'o2des'}>
            <img src={"img/O2DES.png"} alt={'o2des'} width={'100%'} height={'100%'} />
          </div>
          <div className={'studio'}>
            <img src={"img/studio.png"} alt={'studio'} width={'100%'} height={'100%'}  />
          </div>
        </div>
        <div className={'message'}>
          <p>Bring your design to our online tool</p>
        </div>
        <div className={'start'}>
          <input
            type="button"
            onClick={onButtonClick}
            value={loginText}
          />
        </div>
      </div>
    </div>
  )
}