import React from 'react';
import FormBase from './FormBase';
import './LoginForm.css';

export default function LoginForm(prop) {
    return (
        <FormBase title={prop.title} >
            <div className={'loginFormScene'}>
                <img src={'/img/login_scene.png'} alt={'login scene'} width={'100%'} height={'100%'} />
            </div>
            <div className={'loginFormScene_human1'}>
                <img src={'/img/login_scene_human1.png'} alt={'login scene'} width={'100%'} height={'100%'} />
            </div>
            <div className={'loginFormScene_human2'}>
                <img src={'/img/login_scene_human2.png'} alt={'login scene'} width={'100%'} height={'100%'} />
            </div>
            <div className={'loginFormContent'} >
                {prop.children}
            </div>
        </FormBase>
    )
}
