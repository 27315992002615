import React from 'react';
import FormBase from './FormBase';
import './RegisterForm.css';

export default function RegisterForm(prop) {
    return (
        <FormBase title={prop.title} >
            <div className={'registerFormScene'}>
                <img src={'/img/register_scene.png'} alt={'login scene'} width={'100%'} height={'100%'} />
            </div>
            <div className={'registerFormScene_human1'}>
                <img src={'/img/register_scene_human1.png'} alt={'login scene'} width={'100%'} height={'100%'} />
            </div>
            <div className={'registerFormScene_human2'}>
                <img src={'/img/register_scene_human2.png'} alt={'login scene'} width={'100%'} height={'100%'} />
            </div>
            <div className={'registerFormContent'} >
                {prop.children}
            </div>
        </FormBase>
    )
}