import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useGetCookieValue, useSetCookie } from '../utilities/cookie';
import RegisterForm from '../components/RegisterForm';
import LoginForm from '../components/LoginForm';



export default function Profile(prop) {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const [error, setError] = useState('');
  const [nameError, setNameError] = useState('');
  const [emailError, setEmailError] = useState('');
  const [state, setState] = useState("load");
  const [editButtonText, setEditButtonText] = useState("Edit")
  
  const navigate = useNavigate();
  const cookieToken = useGetCookieValue("token");
  const [token, setToken] = useState(cookieToken);
  const setCookie = useSetCookie();
  const src = process.env.REACT_APP_SERVER +"/profile";

  useEffect(() => {
    if(token && token !== "undenfined"){

      const options = { 
        method: "GET",
        headers: {
          "x-authorization-token" : token
        }, 
        mode: "cors",
      };
      
      fetch(src, options)
      .then(async (response) => { 
        var data = await response.json();
        if(response.ok){
          setName(data['name']);
          setEmail(data['email']);
        } else {
          const error = (data && data.message) || response.statusText;
          if (response.status === 401) {
            navigate("/logout");
          }
          return Promise.reject(error);
        }
      })
      .catch(error => {
        console.error("Error", error) ;
        navigate("/");
      })
    } else {
      navigate("/");
    }
  },[token, src, navigate])

  useEffect(() =>{
    if (state === "edited") {
      setEditButtonText("Back");
    } else {
      setEditButtonText("Edit");
    }
  }, [state])

  const onEditButtonClick = () => {
    if(state === "load"){
      setMessage("");
      setState("edit");
    } else {
      navigate(-1)
    }

  }

  const onUpdateButtonClick = () => {
    setError('');
    setNameError('');
    setEmailError('');

    // Check if the user has entered both fields correctly
    if ('' === name.trim()) {
      setNameError('Please enter your name');
      return;
    }
  
    // Check if the user has entered both fields correctly
    if ('' === email.trim()) {
      setEmailError('Please enter your email');
      return;
    }
  
    if (!/^[\w-.]+@([\w-]+.)+[\w-]{2,4}$/.test(email)) {
      setEmailError('Please enter a valid email');
      return;
    }

    const form_url = process.env.REACT_APP_SERVER + "/update_profile"
    const form_token = process.env.REACT_APP_LOGIN_TOKEN
    const form = new FormData()
    form.append("token", token)
    form.append("name", name)
    form.append("email", email)
    fetch(form_url, {
      method: "POST",
      headers: {
        "x-authorization-token" :form_token
      },
      body: form
    })
    .then(
      async (response) => {
        const data = await response.json();
        if (response.ok){
          setState("edited");
          setMessage("Member profile was updated")
          const expiry = new Date(data['expiry']);
          const options = {expires : expiry};
          setCookie(data, options);
          setToken(data["token"]);
        } else {
          const error = (data && data.message) || response.statusText;
          return Promise.reject(error);
        }
    })
    .catch(error => setError(error))
  }

  if(state === "edit")
  {
    return(
      <LoginForm title={"Profile"}>
        <div className={'row'}>
          <label className="error">{error}</label>
        </div>
        <div className={'row'}>
          <label>Name</label>
          <input
            value={name}
            placeholder="Enter your name here"
            onChange={(ev) => setName(ev.target.value)}
            autoComplete='name'
          />
          <label className="error">{nameError}</label>
        </div>
        <div className={'row'}>
          <label>Email</label>
          <input
            value={email}
            placeholder="Enter your email here"
            onChange={(ev) => setEmail(ev.target.value)}
            autoComplete='email'
          />
          <label className="error">{emailError}</label>
        </div>
        <input
          type="button"
          onClick={onUpdateButtonClick}
          value={'Update'}
        />
      </LoginForm>
    )
  }
  else
  {
    return (
      <RegisterForm title={"Profile"}>
        <div className={'row'}>
          <label>{message}</label>
        </div>
        <div className={'row'}>
          <label className="error">{error}</label>
        </div>
        <div className={'row'}>
          <label>Name</label>
          <label>{name}</label>
        </div>
        <div className={'row'}>
          <label>Email</label>
          <label>{email}</label>
        </div>
        <input type="button" onClick={onEditButtonClick} value={editButtonText} />
      </RegisterForm>
    )
  }
}