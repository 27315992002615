import React, { useState, useEffect }  from 'react';
import Markdown from 'react-markdown';
import './FileMarkdown.css';

export default function FileMarkdown(prop) {
    const { src } = prop
    const [post, setPost] = useState('');
  
    useEffect(() => {
        if(src){
            fetch(src)
            .then(response => response.text())
            .then(text => setPost(text))
            .catch(err => console.log(err));
        }
    },[src]);
  
    return (
        <div className='markdown'>
          <Markdown>
            {post}
          </Markdown>
        </div>
    )
  }