import { useCallback, useMemo } from 'react'
import { useCookies } from 'react-cookie'

export const defaultCookieName = process.env.REACT_APP_COOKIE_NAME;

export function useGetCookieValue (key, cookieName = defaultCookieName) {
    const [cookies] = useCookies([cookieName]);
    return useMemo(() => {
        const cookie = cookies[cookieName];
        return (cookie&&cookie[key]&&cookie[key]!=="undefined")?cookie[key]:"";
    }, [cookies, key, cookieName])
}

export function useSetCookie(cookieName = defaultCookieName) {
    const [,setCookies] = useCookies([cookieName]);
    return useCallback((newValue, options) =>{
        setCookies(cookieName, newValue, options);
    },[setCookies, cookieName]);
}

export function useRemoveCookie(cookieName = defaultCookieName) {
    const [,,removeCookies] = useCookies([cookieName]);
    return useCallback(() => {
        removeCookies(cookieName);
    }, [removeCookies, cookieName])
}
   





