import React, {useEffect} from 'react';
import { useNavigate } from 'react-router-dom';
import { useGetCookieValue, useRemoveCookie } from '../utilities/cookie';
import LoginForm from '../components/LoginForm';

export default function Logout(prop) {
  const navigate = useNavigate();
  const removeCookie = useRemoveCookie(); 
  const token = useGetCookieValue("token");

  useEffect(() => {
    const src = process.env.REACT_APP_SERVER + "/logout";
    const headers = { "x-authorization-token" : token };

    const options = { 
      method: "GET",
      headers: headers, 
      mode: "cors",
    }
    
    fetch(src, options)
    .then(async (response) => { 
      removeCookie();
      if(response.ok === false){
        var data = await response.json();
        const error = (data && data.message) || response.statusText;
        return Promise.reject(error);
      }
    })
    .catch(error => console.error("Error", error))
      
    navigate('/');

  }, [token, navigate, removeCookie]);


  return (
    <LoginForm title={"User Logout"}>
      <div className={'row'}>
        <p>User log out was completed</p>
        <p>You will redirect back to home page</p>
      </div>
    </LoginForm>
  )
}