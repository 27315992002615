import React from 'react';
import './about.css';

export default function About(prop) {


  return (
    <div className={"mainContainer"}>
      <div className={"about"}>
        <div className={'content'}>
          <img src="img/about.png" alt="about" width={'100%'} height={'100%'} />
        </div>
      </div>
    </div>
  )
}