import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import LoginForm from '../components/LoginForm';
import RegisterForm from '../components/RegisterForm';

export default function ForgetPassword(prop) {
  const [email, setEmail] = useState('');
  const [error, setError] = useState('');
  const [emailError, setEmailError] = useState('');
  const [success, setSuccess] = useState(false);

  const navigate = useNavigate();

  const onResetButtonClick = () => {
    setError('');
    setEmailError('');
  
    // Check if the user has entered both fields correctly
    if ('' === email) {
      setEmailError('Please enter your email');
      return;
    }
  
    if (!/^[\w-.]+@([\w-]+.)+[\w-]{2,4}$/.test(email)) {
      setEmailError('Please enter a valid email');
      return;
    }
  

    const url = process.env.REACT_APP_SERVER + "/forget_password";
    const login_token = process.env.REACT_APP_LOGIN_TOKEN;
    const form = new FormData();
    form.append("email", email);
    fetch(url, {
      method: "POST",
      headers: {
        "x-authorization-token" :login_token
      },
      body: form
    })
    .then(
      async (response) => {
        const data = await response.json();

        if (response.ok){
          setSuccess(true);
        } else {
          const error = (data && data.message) || response.statusText
          return Promise.reject(error);
        }
    })
    .catch(error => setError(error))
  }

  const onBackButtonClick = () => {
    navigate('/');
  }
  if(success){
    return (
      <RegisterForm title={"Reset Password"}>
        <div className={'row'}>
          <p>Password rest email was sent to your email account ({email}) </p>
          <p>Please apply the link in email for password setup</p>
        </div>
        <input
          type="button"
          onClick={onBackButtonClick}
          value={'Home'}
        />
      </RegisterForm>
    )
  } else {
    return (
      <LoginForm title={"Forget Password"}>
        <div className={'row'}>
          <label className="error">{error}</label>
        </div>
        <div className={'row'}>
          <p>Please fill in your email address for password reset</p>
        </div>
        <div className={'row'}>
          <label>Email</label>
          <input
            value={email}
            placeholder="Enter your email here"
            onChange={(ev) => setEmail(ev.target.value)}
            autoComplete='email'
          />
          <label className="error">{emailError}</label>
        </div>        
        <input type="button" onClick={onResetButtonClick} value={'Reset Password'} />
      </LoginForm>
    )
  }
}