import React  from 'react';
import FormBase from '../components/FormBase';
import FileMarkdown from '../components/FileMarkdown';

export default function Documentation(prop) {

  return (
    <FormBase title="User Guide">
      <FileMarkdown src="doc/document.md" />
    </FormBase>
  )
}
