import { BrowserRouter, Route, Routes } from 'react-router-dom'
import Menu from './components/Menu'
import Home from './pages/home'
import About from './pages/about'
import Design from './pages/design'
import Documentation from './pages/documentation'
import ForgetPassword from './pages/forget_password'
import Login from './pages/login'
import Logout from './pages/logout'
import Profile from './pages/profile'
import Register from './pages/register'
import ResetPassword from './pages/reset_password'
import Tutorial from './pages/tutorial'
import './App.css';

const defaultMenuData = [
  {
      title: 'Home',
      link: '/',
  },
  {
      title: 'About',
      link: '/about',
  },
  {
      title: 'Documentation',
      link: '/documentation',
  },
  {
    title: 'Tutorial',
    link: '/tutorial',
},
];

const loggedInMenuData = [
  ...defaultMenuData,
  {
    title: 'Tool', 
    link: '/design'
  }
];

function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <Menu defaultMenuData = {defaultMenuData} loggedInMenuData = {loggedInMenuData} />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/about" element={<About />} />
          <Route path="/design" element={<Design />} />         
          <Route path="/documentation" element={<Documentation />} />
          <Route path="/forget_password" element={<ForgetPassword />} />
          <Route path="/login" element={<Login />} />
          <Route path="/logout" element={<Logout />} />
          <Route path="/profile" element={<Profile />} />
          <Route path="/register" element={<Register />} />
          <Route path="/reset_password" element={<ResetPassword />} />
          <Route path="/tutorial" element={<Tutorial />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
